<template>
  <section class="page page-tariffs">
    <div class="wrapper">
      <div class="breacrumbs">
        <router-link to="/">Главная</router-link>
        <span class="separator">/</span>
        <span class="current">Тариф platform</span>
      </div>
      <div class="page-title">
        <h1>Общаяя информация по тарифу</h1>
      </div>
      <div>
        <h2>
          Для кого этот тариф?
        </h2>
        <p>
          Данный тариф предназначен для людей, кто хочет получить все платные
          уроки с платформы.
        </p>

        <h2>
          Для чего создана данная группа?
        </h2>
        <p>
          В рамках данного тарифа предоставляется доступ в ТГ чат на один месяц.
        </p>
        <h2>
          Возврат денежных средств.
        </h2>
        <p>
          Каждый студент вправе вернуть деньги, которая высчитывается по
          формуле: полная стоимость курса - 7 процентов. Запрос на возврат
          денежных средств может быть получен не позднее чем 14 дней с момента
          оплаты.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Offer"
};
</script>

<style></style>
